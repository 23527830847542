import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/templates/single-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Throughout school year 2020-2021, Watchung Co-op's Creative Class (2.5-3 years old) and Discovery Pre-K (3-5 years old) have remained open for in-person learning up to five days a week.`}</p>
    <p>{`Below is a detailed description of the health and safety measures the Co-op is following to protect its students, staff, and Co-op families:`}</p>
    <p><em parentName="p">{`Classroom:`}</em></p>
    <ul>
      <li parentName="ul">{`Each Class will have ten to twelve children per day`}</li>
      <li parentName="ul">{`Discovery/Pre-K: To accommodate the child limit, the DPK room will be divided by a five foot tall partition which will span the classroom. One lead teacher and one aide will be assigned to each section`}</li>
      <li parentName="ul">{`Creative: Classroom size will remain consistent with past years and will not exceed ten children`}</li>
    </ul>
    <p><em parentName="p">{`Masks:`}</em></p>
    <ul>
      <li parentName="ul">{`All staff will be required to wear a mask while at school`}</li>
      <li parentName="ul">{`All children will be required to keep a mask in their cubby `}</li>
      <li parentName="ul">{`Children will be encouraged, but not required to wear a mask while at school`}</li>
    </ul>
    <p><em parentName="p">{`Co-op Parents:`}</em></p>
    <ul>
      <li parentName="ul">{`Until further notice, there will not be co-op parents in DPK or Creative`}</li>
      <li parentName="ul">{`Parents will not be allowed in the classrooms`}</li>
    </ul>
    <p><em parentName="p">{`Snack Time:`}</em></p>
    <ul>
      <li parentName="ul">{`DPK and Creative classrooms will continue to have snack time, but it will not be family style, and children will not be able to pass food to one another.`}</li>
      <li parentName="ul">{`Teachers and aides will distribute snack individually to children.`}</li>
    </ul>
    <p><em parentName="p">{`Outside Play:`}</em></p>
    <ul>
      <li parentName="ul">{`This has always been a staple of the Coop and this year will be emphasized even further to allow for more outdoor time. We are currently finalizing plans to effectively separate our playground space to accommodate this, and reduce any potential opportunity for cross-contamination.`}</li>
    </ul>
    <p><em parentName="p">{`Screening Procedures:`}</em></p>
    <ul>
      <li parentName="ul">{`Temperature Check: Staff and children will have their temperature taken each day before entering the classroom.Children’s temperature may be taken by the parent and/or caregiver, if preferred, but it must be done with a staff member present to record into our school health binder.`}</li>
      <li parentName="ul">{`If a child or staff member has a fever of 100.4 of above they will not be able to attend school`}</li>
      <li parentName="ul">{`Watchung Coop will follow state and local guidelines in terms of timing for return to school after a fever.
+We will follow state and local recommendations on quarantine for children and staff who test positive.`}</li>
      <li parentName="ul">{`Watchung Coop will follow the NY/NJ/CT Travel Advisory list.All staff and children who travel to a state on the list will be required to self-quarantine for 14 days upon return before they will be allowed to return to school.`}</li>
      <li parentName="ul">{`If you are planning a trip to one of the states listed below, please notify a staff member or board member.`}</li>
    </ul>
    <p><em parentName="p">{`NY/NJ/CT Travel Advisory List:`}</em></p>
    <ul>
      <li parentName="ul">{`As of December 5, 2020, the State of New Jersey discourages all non-essential travel.`}</li>
      <li parentName="ul">{`Travelers and residents returning from any U.S. state or territory beyond the immediate region (New York, Connecticut, Pennsylvania, Delaware) should self-quarantine for 10 days upon return to New Jersey.`}</li>
      <li parentName="ul">{`Please note a negative Covid-19 test does not replace the need to self-quarantine. That said, should you receive a negative test after your return to New Jersey, the quarantine period may be reduced from 10 days down to 7. `}</li>
    </ul>
    <p><em parentName="p">{`Entering the Classroom:`}</em></p>
    <ul>
      <li parentName="ul">{`We are taking steps to manage the traffic flow at pickup and drop off including marking the entryway to maintain proper social distancing.`}</li>
    </ul>
    <p><em parentName="p">{`Cleaning and Hygiene:`}</em></p>
    <ul>
      <li parentName="ul">{`Hand washing will be required throughout the day, including, but not limited to arrival at school, before and after snack and meal time, after bathroom use, and after playground use.`}</li>
      <li parentName="ul">{`Hand sanitizer will also be available and encouraged to be used throughout the day.`}</li>
      <li parentName="ul">{`At the end of work and meal times, all tables and surfaces will be cleaned with bleach wipes.`}</li>
      <li parentName="ul">{`Toys will be cleaned and sanitized every day after class`}</li>
      <li parentName="ul">{`Dress up clothing will be unavailable for use.`}</li>
      <li parentName="ul">{`Extra large Ziploc bags will be used to store any belongings brought from home, including backpacks, spare clothes, and coats. Lunch boxes will be kept in classroom cubbies.`}</li>
      <li parentName="ul">{`As guidance from the CDC and the state of New Jersey evolves, these requirements may change. We will continue to work to ensure our compliance with all guidelines for the safety of the children in our care. We will continue to inform you of any significant updates.`}</li>
      <li parentName="ul">{`In addition to maintaining the safety of our school, we will also strive to maintain our school’s spirit, amidst all of this uncertainty. We will continue to play outdoors, take neighborhood walks, and focus on the importance of social and emotional development.`}</li>
      <li parentName="ul">{`Teachers continue to brainstorm methods for providing children with the opportunity to serve as the “co-op kid.”`}</li>
    </ul>
    <p><em parentName="p">{`Virtual education:`}</em>{` `}</p>
    <ul>
      <li parentName="ul">{`Virtual education will be offered on a daily basis for any children who are sick, or who are simply uncomfortable attending school in person for a period of time. Daily virtual activities will be provided on the SeeSaw digital platform, in addition to enrichment activities including, but not limited to, Music Together and Maestro Tales.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      